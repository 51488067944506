<template>
    <div class="article-blog-page" @scroll="scrolling()">
        <breadcrumbs :breadcrumbs="breadcrumbs" :scroll="scroll" @goBack="back" />
        <section class="article-blog__banner article-blog__banner--eclipse">
            <img class="article-blog__banner-img" src="@/assets/blog/handling-massive-product-db-in-magento.jpg" alt="" loading="lazy" modernize='sizes=["768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]' />
            <h1 class="article-blog__banner-title">
                BREAKING LIMITS: OUR CASE OF HANDLING MASSIVE PRODUCT DATABASES IN MAGENTO
            </h1>
        </section>
        <div class="container article-blog section_holder">
            <div class="section_title">
                <SidebarBlog :tagFromPost="tags" @searchByTag="goToPath" />
            </div>
            <div class="section_content">
                <div class="section_content__item">
                    <p>
                        Your e-commerce store's success is partially reliant on the enormous databases that enable you to offer goods and services online. Magento is a complex e-commerce platform and working with it is related to having the right server resources. Online store owners with the large product catalog often raise this question: can Magento actually manage a big product database effectively? Follow us as we discover how by optimizing server configuration & indexing you can achieve higher website performance and organize your product database for reliable and efficient data import.
                    </p>
                    <h2 class="section_content__item-title">Case Study: Very Big Products Database In Magento</h2>
                    <p>
                        One of our clients recently required an absolutely massive amount of product data imported into their Magento platform. We're talking <b>over 300 million products!</b> From the very start, this wasn't a walk in the park. In fact, no one in the Magento community had ever tackled something of this magnitude. But as they say — where there's a will, there's a way. Since Magento has the reputation of one of the most stable and scalable e-commerce platforms for growing e-commerce merchants, our <b>WDEVS</b> team decided that this challenge could be a perfect opportunity to showcase our experience in developing working and easy-to-navigate solutions for complex tasks.
                    </p>
                    <p>
                        Wrangling that sheer volume of products into an online store isn't just about adding items — it's an intricate dance of technology involving creating a functional e-commerce engine, and, critically, choosing and configuring top-notch server hardware and software.
                    </p>
                    <p>
                        To kickstart the project, we set up a cloud server that could handle this Herculean task: an <b>AMD Ryzen 9 7950X3D 16-Core Processor</b>, backed by a <b>trio of powerful disk drives</b> (two <b>1.8 TB</b> and one <b>3.6 TB</b>). Then, we installed Ubuntu 20.04 along with all the necessary software to run <b>Magento 2.4.6-p2</b>, the latest version at the time. It was a meticulous setup designed for peak performance, thatis why executing this task required meticulous planning, technical finesse, and a deep understanding of Magento's architect
                        ure.
                    </p>
                    <p>
                        Once Magento was primed, we integrated our customized (specifically for this project) version of Import & Export <a href="https://firebearstudio.com/the-improved-import.html" target="_blank">extension</a> by <a href="https://firebearstudio.com/" target="_blank">Firebear Studio</a>, our long-time partner. This solution was pivotal as it ensured lightning-fast import speeds, crucial for handling such colossal amounts of data. Waiting for weeks or months to process 300+ million products wasn't an option if you aim at enhancing customer experience and website functionality.
                    </p>
                    <p>
                        Our client supplied a staggering <b>600</b> Excel spreadsheets, each packed with <b>half a million</b> product entries. Managing this was definitely not a job for the weak and frail. To process the data, we configured an additional automation system using server Cron Jobs, and initiated the import process.
                    </p>
                    <p>
                        In just a few days, thanks to our team's exceptional work in server configuration and custom development, the impossible became a reality. All products were seamlessly integrated into both the backend and frontend of our client's Magento store.
                    </p>
                    <agile
                        ref="carousel"
                        class="section_content__carousel"
                        :nav-buttons="true"
                        :dots="false"
                        :options="myOptions"
                    >
                        <div>
                            <img src="../../assets/blog/handling-massive-product-db-in-magento/image-slider-01.jpg" alt="" />
                        </div>
                        <div>
                            <img src="../../assets/blog/handling-massive-product-db-in-magento/image-slider-02.jpg" alt="" />
                        </div>
                        <div>
                            <img src="../../assets/blog/handling-massive-product-db-in-magento/image-slider-03.jpg" alt="" />
                        </div>
                    </agile>
                    <p>
                        Our next challenge involved optimizing the indexers for a massive number of products, crucial for seamless browsing, quick page loads, and accommodating a high volume of concurrent users on the client's store. We accumulated our teamss experience to ensure smooth functionality integrating search capabilities, search suggestions, and other essential features required by the cli
                        ent.
                    </p>
                    <p>
                        The standard indexing process in Magento posed a significant limitation — allowing only 5,000 product records per database request. With over hundreds of millions of products, this default process would have taken a long time, potentially lasting months. So again, we had to step-in and customize the Magento core to amplify the indexing speed while maintaining system stability.
                    </p>
                    <p>
                        The result was staggering: a monumental leap in indexing efficiency! The indexing process has become hundred times faster and itrs possible now to re-index all data regularly without any risk to overload the ser
                        ver:
                    </p>
                    <div class="section_content__img">
                        <img src="@/assets/blog/handling-massive-product-db-in-magento/image-02.jpg" alt="Article image 1" title="Article image 1" loading="lazy" modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]' />
                    </div>
                    <p>
                        When it comes down to it, our Magento expertise and top-notch custom development team is a key to success that allows us to bridge the gap between technology and business goals, and take on challenges others wouldn't dare touch. WDEVS optimize for scalability, ensuring your website can handle not just the current load but also future growth. We are geared towards problem-solving, that means, we address potential risks before they show up on your radar.
                    </p>
                    <p>
                        Our team's track record isn't just about technical know-how as by partnering with us, you're choosing a reliable service provider; but also gaining access to craft digital storefronts that become the cornerstone of your success in the online retail arena.
                    </p>
                </div>
                <div>
                    <div class="footer-blog-item">
                        <div class="btn-read-more primary contacts-button small" id="show-modal" @click="this.$router.push('/blog')">
                            <span class="button-content">
                                <span class="icon">
                                    <img v-svg-inline :data-src="require('@/assets/icons/rarr-icon.svg')" alt="" />
                                </span>
                                Read more our stories
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarBlog from "./Sidebar.vue";
import Breadcrumbs from "/src/components/Breadcrumbs";
import { VueAgile } from "vue-agile";
import { mapState } from "vuex";

export default {
    name: "BlogMassiveProductDB",
    agile: VueAgile,
    data() {
        return {
            backHistory: history.state.back,
            tags: ['Magento', 'eCommerce', 'Data Import', 'Product Database', 'Magento Database', 'Custom Web Development', 'Online Retail', 'Magento Services'],
            scroll: false,
            position: null,
            breadcrumbs: [
                {
                    link: '/',
                    name: 'Home',
                },
                {
                    name: 'Blog',
                    link: '/blog',
                },
                {
                    name: 'Handling Massive Product Databases In Magento',
                },
            ],
            myOptions: {
                responsive: [
                    {
                        breakpoint: 300,
                        settings: {
                            slidesToShow: 1,
                        },
                    },
                ],
            },
        };
    },
    components: {
        SidebarBlog,
        Breadcrumbs,
    },
    computed: {
        ...mapState(['blog'])
    },
    methods: {
        goToPath(tag) {
            let post = this.blog.find(item => item.tags.includes(tag))
            this.$router.push(post.img_src)
        },
        back() {
            if (!document.referrer || this.backHistory !== '/blog') {
                Object.defineProperty(document, "referrer", {
                    get: function () {
                        return `${window.location.origin}/blog`;
                    },
                });
                this.$router.push('/blog')
                return
            } else {
                this.$router.go(-1)
            }
        },
        scrolling() {
            const scroll = document.documentElement.scrollTop;
            if (scroll > 50) {
                this.scroll = true;
            } else {
                this.scroll = false;
            }
        },
    },
    created() {
        console.log(history.state.back)
        window.history.scrollRestoration = "auto";
        window.addEventListener("scroll", this.scrolling);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.scrolling);
    },
}
</script>

<style lang="scss">
@import "../../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.article-blog-header {
    .article-blog-review__title {
        padding: 120px 0;
        text-align: end;
        text-transform: uppercase;
        color: $black;
        font-size: 32px;
        line-height: 111%;
        margin: 0;

        @include media(">=tablet") {
            font-size: 40px;
            max-width: 100%;
        }

        @include media(">=tablet-xl") {
            font-size: 56px;
        }

        @include media(">=widescreen") {
            font-size: 72px;
            max-width: 100%;
        }
    }
}

.footer-blog-item {
    display: block;
    padding-top: 20px;

    @include media(">=tablet") {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .article-blog-tags {
        margin-top: 40px;
        color: $red;
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;

        @include media(">=tablet") {
            margin-top: 0;
            text-align: center;
        }

        span {
            margin-right: 20px;

            @include media(">=widescreen") {
                margin-right: 40px;
            }
        }
    }
}

.article-blog-page {
    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .link {
        color: $red;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    .btn-read-more {
        .button-content {
            border-radius: 26px;
            padding: 2px 12px 3px;
            background-color: $red;
            color: $white;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            cursor: pointer;
            width: max-content;
            background-image: linear-gradient($darkRed, $darkRed);
            background-repeat: no-repeat;
            transition: background-size 0s, color 0s;
            background-size: 0 100%;

            @include media(">=tablet-xl") {
                padding: 6px 24px 8px;
            }

            &:hover {
                background-size: 100% 100%;
                color: $white;
            }
        }

        .icon {
            padding-top: 6px;
            margin-right: 15px;
            transform: rotateY(180deg);
        }

        a {
            color: $white;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .go-back-to-read {
        padding: 16px 0;
        border-bottom-left-radius: 26px 26px;
        border-bottom-right-radius: 26px 26px;
        position: fixed;
        background: $white;
        z-index: 50;
        width: 100vw;
        transition: all 0.3s;

        @include media(">=tablet-xl") {
            padding: 16px 0;
        }

        &.scrolling {
            padding: 5px 0;
            border-bottom: 1px solid $red;

            @include media(">=tablet-xl") {
                padding: 5px 0;
            }
        }
    }
}

.article-blog {
    &.section_holder {
        display: flex;
        flex-direction: column-reverse;

        @include media(">=tablet-xl") {
            flex-direction: row;
        }
    }

    .section_title {
        margin-right: 0;
        width: 100%;

        @include media(">=tablet-xl") {
            margin-right: 43px;
            width: 35%;
        }
    }

    .section_content {
        margin-bottom: 60px;
        display: block;
        margin-left: 0;
        width: 100%;

        a {
            color: $red;
        }

        &__item {
            margin-bottom: 40px;

            @include media(">=tablet") {
                margin-bottom: 60px;
            }

            @include media(">=desktop") {
                margin-bottom: 120px;
            }

            &-title {
                &-icon {
                    flex-shrink: 0;
                    width: 400px;
                    height: auto;
                    margin-right: 15px;

                    @media all and (max-width: 480px) {
                        width: 100%;
                        margin-right: 0;
                    }

                    svg {
                        max-width: 100%;
                        width: 100%;
                        max-height: 100%;
                        height: 100%;
                    }
                }

                &--icon {
                    display: flex;
                    align-items: flex-end;

                    @media all and (max-width: 767px) {
                        flex-wrap: wrap;
                    }

                    @media (min-width: 992px) and (max-width: 1199px) {
                        flex-wrap: wrap;
                    }
                }
            }

            &-slider {
                position: relative;
            }

            &-link {
                display: inline-block;

                &:hover {
                    text-decoration: none;
                }
            }

            blockquote {
                margin: 25px 0;
                border-left: 3px solid lighten($base-text-color, 25%);
                padding: 10px 25px;
                color: lighten($base-text-color, 25%);
            }
        }

        @include media(">=tablet-xl") {
            margin-left: 37px;
            width: 65%;
            margin-bottom: 0;
        }

        &__paragraph {
            display: flex;
            margin-bottom: 45px;

            &-icon {
                flex-shrink: 0;
                display: inline-block;
                width: 65px;
                height: 65px;
                margin-right: 10px;
                vertical-align: middle;

                @media all and (min-width: 768px) {
                    width: 100px;
                    height: 100px;
                    margin-right: 15px;
                }

                svg {
                    max-width: 100%;
                    width: 100%;
                    max-height: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.article-blog__banner {
    position: relative;
    height: 300px;
    margin-bottom: 60px;

    @include media(">=tablet") {
        height: 500px;
    }

    @include media(">=desktop") {
        height: 600px;
        margin-bottom: 120px;
    }

    &--eclipse {
        z-index: 1;

        &:before {
            content: "";
            position: absolute;
            z-index: 2;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.3);
        }

        .article-blog__banner-title {
            z-index: 3;
        }
    }

    &-img,
    picture {
        display: block;
        height: 100%;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.article-blog__banner-title {
    text-transform: uppercase;
    color: $white;
    font-size: 32px;
    line-height: 111%;
    position: absolute;
    bottom: 20px;
    left: 16px;
    max-width: calc(100% - 32px);
    margin: 0;

    @include media(">=tablet") {
        bottom: 40px;
        font-size: 40px;
        left: 40px;
        max-width: calc(100% - 80px);
    }

    @include media(">=tablet-xl") {
        bottom: 80px;
        left: 40px;
        font-size: 56px;
        max-width: calc(100% - 80px);
    }

    @include media(">=widescreen") {
        bottom: 80px;
        left: 80px;
        font-size: 72px;
        max-width: 1150px;
    }
}

.article-blog__img,
picture {
    margin: 0 0 40px;
    display: block;
}

.article-blog__customer {
    em {
        display: block;
        margin-top: 32px;
    }

    .button {
        margin-top: 40px;
    }
}

.section_content__carousel {
    margin-bottom: 40px;
    .agile__nav-button {
        border-radius: 50%;
        background-color: $red;
        color: transparent;
        transition: background-color .3s;
        &:after {
            content: url("data:image/svg+xml,%3Csvg width='20' height='32' viewBox='0 0 20 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.2622 18.2616L5.46234 31.0614C4.83728 31.6865 4.0188 31.999 3.19996 31.999C2.38113 31.999 1.56265 31.6865 0.93759 31.0614C-0.31253 29.8124 -0.31253 27.7854 0.93759 26.5363L11.4747 15.9992L0.93759 5.46172C-0.31253 4.21267 -0.31253 2.18567 0.93759 0.936613C2.18771 -0.313506 4.21258 -0.313506 5.46269 0.936613L18.2626 13.7365C19.5123 14.9855 19.5123 17.0129 18.2622 18.2616Z' fill='white'/%3E%3C/svg%3E%0A");
            position: absolute;
            top: calc(50% - 16px);
            width: 20px;
            height: 32px;
        }
        &:hover {
            background-color: $darkenRed;
        }
        &--prev {
            left: 10px;
            &:after {
                left: calc(50% - 12px);
                transform: rotate(180deg);
            }
        }
        &--next {
            right: 10px;
            &:after {
                left: calc(50% - 6px);
            }
        }
    }
}
</style>
